/*
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');
@import url('https://cdn.jsdelivr.net/npm/remixicon@2.5.0/fonts/remixicon.css');
*/


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Arimo',
    monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&display=swap');
@import url('https://cdn.jsdelivr.net/npm/remixicon@2.5.0/fonts/remixicon.css');



*{
  font-family: 'Arimo', sans-serif !important;
}
.h-screen {
  height: 100vh;
}

.flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-end {
  justify-content: flex-end;
}

.items-center {
  align-items: center;
}
.items-end {
  align-items: flex-end;
}


.loader-parent{
  position: fixed;
  inset: 0;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  z-index: 9999 !important;
  background-color: rgba(255, 255, 255, 0.664);
  /*background-color: rgba(43, 43, 43, 0.664);*/
  /*background-color: white;*/
}

.w-50{
  width: 50%;
}
.w-100{
  width: 100%;
}

i{
  font-size: 1.4rem;
  cursor: pointer;
}

input{
   border : 1px solid rgb(79, 83, 83) !important;
}

/*
.container{
  height: 85vh !important;
}
*/

/*.mantine-xoj88x{
  height: 100% !important;
  overflow: scroll !important;
}*/

.auth{
  background-color: #F0F0F0;
}

.my-custom-table th {
  background-color: #fffdfd;
  /* Color de fondo para encabezados */
  border-bottom: 1px solid #ddd;
  /* Línea divisoria en la parte inferior de las celdas del encabezado */
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-top: 1px solid #ddd;

  text-align: center;
  /* Centra el texto */
  background-color: hsl(0, 0%, 100%);
  /* Color de fondo */
  color: rgb(0, 0, 0);
  /* Color del texto */
  padding: 5px;
  
  max-width: 100%;
  min-width: 130px;
}

.my-custom-table td {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  /* Define el color y grosor de la línea divisiva en la parte superior e inferior */
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  padding-left: 5px;
  text-align: center;
}

.my-custom-table tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
  /* Fondo de filas impares */
}

.my-custom-table tbody tr:hover {
  background-color: #e0e0e0;
  /* Color de fondo al pasar el ratón sobre una fila */
}

.native-select-container {
  width: 60%;
  position: relative;
}

.native-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  appearance: none;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='32' height='32'%3E%3Cpath d='M16 24l-8-8 1.41-1.41L16 21.17l6.59-6.58L24 16z'/%3E%3C/svg%3E") no-repeat right 10px center/10px 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.native-select:focus {
  outline: none;
  border-color: #007bff;
}

.AccordionControl {
  background-color: #00497A; /* Cambia este valor al color que prefieras */
  color: white;
  border-top-left-radius: 4px; /* Ajusta el valor según el radio de borde deseado */
  border-top-right-radius: 4px;

}

.AccordionControlUsuariosForm {
  background-color: #00497A; /* Cambia este valor al color que prefieras */
  color: white;
  width: 300px;
}

.AccordionControlTabla {
  background-color: #ffffff; /* Cambia este valor al color que prefieras */
  color: rgb(40, 40, 40);
}

.AccordionControl:hover {
  background-color: #00497A; /* Mantener el mismo color de fondo en hover */
}

.AccordionControlUsuariosForm:hover {
  background-color: #00497A; /* Mantener el mismo color de fondo en hover */
}

.AccordionControl2 {
  background-color: white; /* Cambia este valor al color que prefieras */
  color: black;
  border: 1px solid black; /* Set the border to 1px width solid black */
  margin: 5px;
  width: 100%;
}

.AccordionControl2:hover {
  background-color: white; /* Mantener el mismo color de fondo en hover */
}

.CardTituloCatalogos {
  margin-bottom: 15px;
  border: 1px solid rgb(216, 216, 216);
  /*box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.1); /* Añadir sombra */}

.AccordionPanel {
  background-color: white;
  border: 1px solid rgb(216, 216, 216);
  padding: 10px;
  margin-bottom: 15px;
  /*box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.1); /* Añadir sombra */
  border-bottom-left-radius: 4px; /* Ajusta el valor según el radio de borde deseado */
  border-bottom-right-radius: 4px;

}

.CardTituloReportes {
  margin-bottom: 10px;
  border: 1px solid rgb(216, 216, 216);
  /*box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.1); /* Añadir sombra */

}

.CardTablas {
  margin-bottom: 10px;
  border: 1px solid rgb(216, 216, 216);
  /*box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.1); /* Añadir sombra */

}


.NavBarCard {
/*  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.1);*/ /* Añadir sombra */
}




.HeaderCard {
/*  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Añadir sombra */
}

.textarea-fullwidth {
  width: 100%;
}

@media (max-width: 768px) {
  .textarea-fullwidth {
    width: 100%;
  }
}

.whiteBurger path {
  stroke: white;
}

.caja-350 {
  width: 350px;
}

.caja-grande {
  width: 300px;
}

.caja-extragrande {
  width: 430px;
}

.caja-estandar {
  width: 200px;
}

.caja-chica {
  width: 150px;
}

.caja-sintomas {
  width: 100%;
  max-width: 300px;
}

@media (max-width: 768px) {
  .group-no-wrap {
    flex-direction: column;
  }

  .caja-grande,
  .caja-extragrande,
  .caja-sintomas,
  .caja-estandar,
  .caja-chica,
  .caja-200,
  .caja-280,
  .caja-350,
  .caja-100,
  .caja-400,
  .caja-450,
  .caja-600,
  .caja-80p,
  .caja-75p,
  .caja-20p {
    width: 100%;
  }
}

.Filtro {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(135px, 1fr));
  gap: 8px;
  margin-bottom: 15px;
}

.caja-150 {
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .Filtro {
    grid-template-columns: 1fr;
  }
}


/*Estilos Seccion Tabla Cultivos*/
/* General styles */
.responsive-table-container {
  width: 100%;
  overflow-x: auto;
}

.my-custom-table {
  width: 100%;
  min-width: 430px; /* Adjust this value based on your content */
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .responsive-table-container {
    max-height: 200px; /* Adjust this value as needed */
    overflow-y: auto;
  }

  .my-custom-table th,
  .my-custom-table td {
    white-space: nowrap;
  }

  .my-custom-table .datos-column,
  .my-custom-table .antibiograma-column {
    max-width: 200px; /* Adjust this value based on your content */
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

/* Optional: Improve scrollbar appearance for webkit browsers */
.responsive-table-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.responsive-table-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.responsive-table-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}


.responsive-div {
  width: 80%;
  /* Otros estilos relevantes */
}

@media screen and (max-width: 768px) {
  .responsive-div {
      width: 100%;
  }
}

.responsive-div2 {
  width: 85%;
  margin-left: 3px;
  /* Otros estilos relevantes */
}

@media screen and (max-width: 768px) {
  .responsive-div2 {
      width: 100%;
  }
}

.responsive-div-reportetabla3 {
  width: 100%;
  gap: 20px; /* Espacio entre los elementos */
  display: flex;
  flex-wrap: wrap; /* Permitir que los elementos se apilen en pantallas pequeñas */
}

@media screen and (max-width: 768px) {
  .responsive-div-reportetabla3 {
      width: 100%;
  }
}

/* para que el div padre que tiene a los dos divs (cultivos y tratamientos) ponga dichos divs uno de lado del otro en pantallas grandes y uno debajo de otro en pantallas chicas*/
.responsive-flex-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media screen and (max-width: 768px) {
  .responsive-flex-container {
      flex-direction: column;
      align-items: stretch; /* Opcional: asegura que los elementos ocupen todo el ancho disponible */
  }
}

.responsive-cards-container {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-x: auto; /* Si hay desbordamiento horizontal, permite el scroll */
}

.card-content {
  word-wrap: break-word; /* Rompe palabras largas si es necesario */
  word-break: break-all; /* Fuerza el corte de palabras largas sin espacios */
  white-space: pre-wrap; /* Mantiene los saltos de línea y ajusta las palabras */
  max-width: 100%; /* Asegura que el contenido no exceda el contenedor */
  overflow-wrap: break-word; /* Asegura el ajuste adecuado para navegadores modernos */
}

.CardTablasReporteTabla3 {
  flex: 1 1 40%;
  background-color: white;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 768px) {
  .CardTablasReporteTabla3 {
    flex: 1 1 100%; /* Ocupan 100% del ancho en pantallas pequeñas */
  }
}

.CardTablasReporteTabla4 {
  flex:1 1 50%; /* Tarjetas ocupan inicialmente 50% */
  background-color: white;
}

@media screen and (max-width: 768px) {
  .CardTablasReporteTabla4 {
    flex: 1 1 100%; /* Ocupan 100% del ancho en pantallas pequeñas */
  }
}

/* En tu archivo CSS */
.fieldset-container {
  display: flex;
  gap: 15px;
  align-items: flex-start;
}

.fieldset-container .mantine-Fieldset-root {
  flex: 1;
}

@media (max-width: 768px) {
  .fieldset-container {
      flex-direction: column;
  }
  .fieldset-container .mantine-Fieldset-root {
      width: 100%;
  }
}


