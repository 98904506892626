.control {
  font-weight: 500;
  display: block;
  width: 100%;
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
  /*color: var(--mantine-color-text);*/
  color: white; /* Cambia el color del texto a blanco */
  font-size: var(--mantine-font-size-sm);

  @mixin hover {
    background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-7));
    color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));
  }
}

.link {
  font-weight: 300;
  display: block;
  text-decoration: none;
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
  padding-left: var(--mantine-spacing-md);
  margin-left: var(--mantine-spacing-xl);
  font-size: var(--mantine-font-size-sm);
  /*color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0));*/
  color:white; /* Poner color en blanco*/

  border-left: 1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));

  @mixin hover {
    background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-7));
    color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));
  }
}

.chevron {
  transition: transform 200ms ease;
}